<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">

            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-row>
                <v-col>
                    <v-select :items="listcible2" v-model="selectcible2" @change="ciblechange()" item-value="id" outlined label="Liste des cibles">
                        <template slot="selection" slot-scope="data">
                            {{ data.item.code }} - {{ data.item.description }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.code }} - {{ data.item.description }}
                        </template>
                    </v-select>

                </v-col>
                <v-spacer></v-spacer>

                <v-col>
                    <v-btn color="green" dark class="mb-2" @click="initialize()">
                        Actualisation
                    </v-btn>

                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="list" sort-by="id" class="elevation-1">
                <template v-slot:[`item.image`]="{ item }">
                    <div class="p-2">
                        <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
                    </div>
                </template>

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Liste Indicateurs ODD</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-select :items="listcible" v-model="selectcible" dense item-value="id" :rules="[v => !!v || 'Veuillez selectionnez le odd']" required outlined label="Liste des cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>
                                            <v-text-field v-model="code" label="Indicateur CODE" dense outlined required></v-text-field>
                                            <v-textarea v-model="libelle" label="Libelle de l'indicateur" dense outlined required></v-textarea>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=flase">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.odd`]="{ item }">
                    {{ item.code }} - {{ item.libelle }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                        Actualiser la liste
                    </v-btn>
                </template>
            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbartest: false,
        textmessage: '',
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Code Indicateur',
                value: 'code',
                divider: true,
                width: '10px'
            },
            {
                text: 'Indicatleur',
                align: 'start',
                sortable: false,
                value: 'libelle',
                divider: true,
                width: '350px'
            },
            {
                text: 'cible',
                align: 'start',
                sortable: false,
                value: 'cibles.description',
                divider: true,
                width: '350px'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '10px'
            },
        ],
        formTitle: 'Formulaire',
        desserts: [],
        list: [],
        listcible: [],
        listcible2: [],

        valid: true,
        image: null,

        id_cibles: '0',
        selectcible: '0',
        selectcible2: '0',
        code: '',
        libelle: '',
        editedIndex: -1,
        textetat: false,
        messages: '',

    }),

    methods: {
        initialize() {
            this.selectcible2 = '0';
            this.readAll()
         
        },
        //=====Affichages  liste des odds==========
        readAll: async function () {
            const data = await api.readAll('indicateur-odd/all');
            this.list = data.list;
            this.list2 = data.list;
        },

        readcibleAll: async function () {
            const data = await api.readAll('cibles/all');
            this.listcible = data.list;
            this.listcible2 = data.list;

        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('indicateur-odd/delete', fd);
            if (res.status == 200) {

            }
            // this.messagesligne(res);
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_cibles', this.selectcible);
            fd.append('code', this.code);
            fd.append('libelle', this.libelle);

            if (this.textetat) {

                const res = await api.createUpdatedata('indicateur-odd/update/' + this.id_odds, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                    this.clear();

                } else if (res.status == 401) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Eurror",
                        text: this.textmessage,
                        visible: true
                    };

                }

            } else {
                const res = await api.createUpdatedata('indicateur-odd/add', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.clear();

                } else if (res.status == 401) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }

            }
            this.readAll();
        },
        nouveau() {
            this.id_odds = "0";
            this.libelle = "";
            this.code = "";
            this.selectcible = '0';
            this.textetat = false;

            //this.dialog=false;
        },

        clear() {

            this.id_odds = "0";
            this.libelle = "";
            this.code = "";
            this.textetat = false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id_odds = item.id;
            this.libelle = item.libelle;
            this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false
        },

        closeDelete() {
            this.dialogDelete = false
        },

        save() {
            if (this.libelle == '' || this.code == '') {

                this.messages = "Veuillez remplir les champs"
                return;
            }
            this.createUpdatedata();

        },
        ciblechange() {
            this.list = [];
            this.list = this.list2.filter(this.filtrerParID);
        },

        filtrerParID: function (obj) {
            if (obj.id_cibles == this.selectcible2) {

                return true;
            } else {
                return false;
            }
        },
    },
    mounted() {
        this.readAll();
        this.readcibleAll();
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

}
</script>
